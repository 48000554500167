<template>
    <div class="container page">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 text-center">
                <b-img fluid :src="logo" class="w-75"></b-img>
                <form class="form-signin">
                    <h1 class="h3 font-weight-normal text-center text-nowrap">{{$applicationTitle}}<span v-if="process_name">{{' : ' + process_name}}</span></h1>
                    <br />
                    <div v-if="api_error">
                        <b-alert
                            :show="alertDismissCountDown"
                            dismissible
                            variant="danger"
                            @dismissed="alertDismissCountDown=0"
                            @dismiss-count-down="alertCountDownChanged"
                        >{{api_error}}</b-alert>
                    </div>
                    <br />
                    <label class="sr-only">Email</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        required
                        autofocus
                        name="email"
                        v-model="input.email"
                        :class="{ 'is-invalid': !input.email && error.status }"
                    />
                    <div
                        v-show="!input.email && error.status"
                        class="invalid-feedback"
                    >{{ error.email }}</div>
                    <label class="sr-only">Password</label>
                    <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        required
                        autofocus
                        name="password"
                        v-model="input.password"
                        :class="{ 'is-invalid': !input.password && error.status }"
                        v-on:keyup.enter="login()"
                    />
                    <div
                        v-show="!input.password && error.status"
                        class="invalid-feedback"
                    >{{ error.password }}</div>
                    <button
                        type="button"
                        class="btn btn-md btn-primary btn-block"
                        style="margin-top:50px;"
                        v-on:click="login()"
                    >Login</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import default_error from "../../assets/js/global";
export default {
    data() {
        return {
            input: {
                email: "",
                password: ""
            },
            error: {
                email: "email is Required",
                password: "Password is Required",
                status: false
            },
            output: [],
            logo: this.$api.host_url + "images/logo.png",
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            api_error: ""
        };
    },
    created() {
        if (window.$cookies.isKey("user")) {
            const user = window.$cookies.get("user");
            if (user.access_token != (null || undefined)) {
                this.$router.push({ name: "home" });
            }
        }
    },
    computed: {
        process_name() {
            return this.$store.getters.getAppSetting("user_interface");
        }
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        login: function() {
            if (this.input.email != "" && this.input.password != "") {
                this.startProgressBar();
                this.axios
                    .post(this.$api.login, this.input, this.$apiHeaders)
                    .then(response => {
                        let data = response.data.data[0];
                        window.$cookies.set("user", data, 0);
                        this.updateProgressBar(true);
                        this.$router.push({ name: "home" });
                    })
                    .catch(err => {
                        if (!err.response) {
                            this.api_error = default_error.server_error;
                        } else if (
                            err.response &&
                            err.response.data &&
                            err.response.data.message
                        ) {
                            this.api_error = err.response.data.message;
                        } else {
                            this.api_error = default_error.server_error;
                            console.error(err.response.data);
                        }
                        this.showAlert();
                        this.updateProgressBar(false);
                    });
            } else {
                this.error.status = true;
                console.error(this.error);
            }
        },
    }
};
</script>
<style lang="scss">
.page-top {
    margin-top: 60px;
}
.invalid-feedback {
    width: 100% !important;
    font-size: 100% !important;
    margin: 0rem 0rem 1rem 0.5rem;
}
.page {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #2e2e2e;
}
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}
.form-signin input {
    margin-bottom: 20px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}
.checkbox {
    margin-bottom: 20px;
    margin-top: 20px;
}
</style>